import React from "react";

const Footer = () => {
  return (
    <>
      <div className="container-fluid container py-3">
        <hr />
        <p>Copyright © FlashMillionaire.com 2024</p>
      </div>
    </>
  );
};

export default Footer;
