/* global BigInt */

import React from "react";
import { ethers } from "ethers";
import { useState, useEffect } from "react";
import Message from "./Message";
import MessageType from "./MessageType";
import Helper from "../Helper.js";
import config from "../config/developmentConfig.json";
import { DaiABI, DummyWorkerABI, MillionaireABI } from "./ABIs.js";
import { useMutex } from "react-context-mutex";

const BeAMillionaire = ({
  wallet,
  walletChain,
  flashMillionAmount,
  minimumMillionAmount,
}) => {
  const [isFreeVersionChecked, setIsFreeVersionChecked] = useState(false);
  const [activeWallet, setActiveWallet] = useState(null);
  const [activeChain, setActiveChain] = useState(null);
  const [networkId, setNetworkId] = useState(-1);
  const [beAMillionaireMessage, setBeAMillionaireMessage] = useState(
    new Message(null, MessageType.Info)
  );
  const [signerProvider, setSignerProvider] = useState(null);
  const [millionaireTxCheckerCount, setMillionaireTxCheckerCount] =
    useState(-1);
  const [daiConfirmationTxCheckerCount, setDaiConfirmationTxCheckerCount] =
    useState(-1);
  const [millionaireTxHashToCheck, setMillionaireTxHashToCheck] =
    useState(null);
  const [daiConfirmationTxHashToCheck, setDaiConfirmationTxHashToCheck] =
    useState(null);
  const [timerTrigger, setTimerTrigger] = useState(false);
  const [onStartButtonActive, setOnStartButtonActive] = useState(true);

  const MillionaireMutexRunner = useMutex();
  const millionaireMutex = new MillionaireMutexRunner("MillionaireMutex");

  const DaiConfirmationMutexRunner = useMutex();
  const daiConfirmationMutex = new DaiConfirmationMutexRunner(
    "DaiConfirmationMutex"
  );

  const MaxMillionaireTxCheckerCount = 10;
  const MaxDaiConfirmationTxCheckerCount = 10;

  useEffect(() => {
    if (activeWallet !== wallet) {
      console.log("BeAMillionaire: new wallet.");
      setActiveWallet(wallet);
      setActiveChain(walletChain);
      !!walletChain ? setNetworkId(Number(walletChain.id)) : setNetworkId(-1);
    }

    getSignerProvider();

    // Theses two will stop the refresh if it is in progress
    setMillionaireTxHashToCheck(null);
    setDaiConfirmationTxHashToCheck(null);

    // This will reactivate the start button, if it not active
    setOnStartButtonActive(true);

    setBeAMillionaireMessage(new Message("", MessageType.Info));
  }, [wallet]);

  useEffect(() => {
    getSignerProvider();
  }, []);

  // We should not create timer outside of useEffect, it will fire too fast
  // The following was created with the help of
  // https://stackoverflow.com/questions/68784567/react-rendering-setinterval-just-keeps-going-faster-and-unpredictable
  useEffect(() => {
    // Store the interval id in a const, so you can cleanup later
    const intervalId = setInterval(() => {
      console.log("Check transacton timer entered ...");
      if (!!millionaireTxHashToCheck || !!daiConfirmationTxHashToCheck) {
        setTimerTrigger((timerTrigger) => !timerTrigger);
      }
    }, 5000);

    return () => {
      clearInterval(intervalId);
      console.log("Clean up code called.");
    };
  }, [millionaireTxHashToCheck, daiConfirmationTxHashToCheck]);

  useEffect(() => {
    const checkTransactionWorker = async (
      txHashToCheck,
      setTxHashToCheck,
      txCheckerCount,
      setTxCheckerCount,
      MaxTxCheckerCount,
      successMessage,
      inProgressMessage,
      failureMessage,
      onSuccess,
      onFailure
    ) => {
      try {
        // increasing the retry counter
        if (!!txHashToCheck && txCheckerCount < MaxTxCheckerCount) {
          setTxCheckerCount(txCheckerCount + 1);
        }

        // doing the actual work
        let transactionLinkMessage =
          "Transaction could be found " +
          "<a href='" +
          Helper.getEtherScanUri(networkId, txHashToCheck) +
          "'>here</a>.";
        if (txCheckerCount <= MaxTxCheckerCount && !!txHashToCheck) {
          var provider = new ethers.providers.JsonRpcProvider(
            Helper.getRPCProvider(networkId)
          );
          let txResult = await provider.waitForTransaction(txHashToCheck, 0);
          // txResult = null; // For test purpose
          console.log("txResult: ", txResult);
          if (!!txResult) {
            setBeAMillionaireMessage(
              new Message(
                successMessage + transactionLinkMessage,
                MessageType.Success
              )
            );
            setTxHashToCheck(null);

            if (!!onSuccess) {
              onSuccess();
            }
          } else {
            let counter = Number(txCheckerCount) + 1; // +1 is because the state update at the top does not take effect immediately

            if (txCheckerCount < MaxTxCheckerCount) {
              setBeAMillionaireMessage(
                new Message(
                  inProgressMessage +
                    counter +
                    " times. " +
                    transactionLinkMessage,
                  MessageType.Info
                )
              );
            } else {
              // millionaireTxCheckerCount = MaxMillionaireTxCheckerCount
              setBeAMillionaireMessage(
                new Message(
                  failureMessage +
                    counter +
                    " times. " +
                    transactionLinkMessage +
                    " You can check it manually, it will probably mined successfully shortly.",
                  MessageType.Error
                )
              );

              setTxHashToCheck(null);

              if (!!onFailure) {
                onFailure();
              }
            }
          }
        }
      } catch (error) {
        if (!!onFailure) {
          onFailure();
        }
        setBeAMillionaireMessage(new Message(error.message, MessageType.Error));
      }
    };

    const checkTransaction = async () => {
      console.log("Check transaction entered ...");
      // If we can not proceed, we immediately return, in order for the events not be collected on top of each other,
      // and when the congestion is started to be removed, all of a sudden a lot of event processing occurs without enough time interval between them;
      // for example after approval is given and we are waiting for millionaire making transaction
      if (millionaireMutex.isLocked()) {
        console.log(
          "Millionaire Mutex is locked. Not proceeding further ... ."
        );
      } else {
        millionaireMutex.run(async () => {
          try {
            millionaireMutex.lock();

            let transactionAmount = isFreeVersionChecked
              ? Number(config.minimumMillion).toLocaleString()
              : Number(config.flashMillionAmount).toLocaleString();
            let successMessage = "Equivalent of ";
            successMessage = successMessage.concat(
              transactionAmount,
              " USD has been transacted through your account. "
            );
            let transactionInProgressMessage = transactionAmount.concat(
              " USD transaction through your account is not still mined. It has been checked "
            );
            let transactionFailureMessage = transactionAmount.concat(
              " USD transaction through your account is not still mined and will not be checked anymore. It has been checked "
            );

            await checkTransactionWorker(
              millionaireTxHashToCheck,
              setMillionaireTxHashToCheck,
              millionaireTxCheckerCount,
              setMillionaireTxCheckerCount,
              MaxMillionaireTxCheckerCount,
              successMessage,
              transactionInProgressMessage,
              transactionFailureMessage,
              () => {
                setOnStartButtonActive(true);
              },
              () => {
                setOnStartButtonActive(true);
              }
            );
          } finally {
            if (millionaireMutex.isLocked()) {
              millionaireMutex.unlock();
            }
          }
        });
      }

      if (daiConfirmationMutex.isLocked()) {
        console.log(
          "Dai confirmation Mutex is locked. Not proceeding further ... ."
        );
      } else {
        daiConfirmationMutex.run(async () => {
          try {
            daiConfirmationMutex.lock();

            await checkTransactionWorker(
              daiConfirmationTxHashToCheck,
              setDaiConfirmationTxHashToCheck,
              daiConfirmationTxCheckerCount,
              setDaiConfirmationTxCheckerCount,
              MaxDaiConfirmationTxCheckerCount,
              "Dai approval completed. Transaction hash is: ",
              "Dai approval transaction still not mined. It has been checked ",
              "Dai approval transaction check failure. It has been checked ",
              async () => {
                await ActuallyMakeTheMillionaire(isFreeVersionChecked, null);
              },
              () => {
                setOnStartButtonActive(true);
              }
            );
          } finally {
            if (daiConfirmationMutex.isLocked()) {
              daiConfirmationMutex.unlock();
            }
          }
        });
      }
    };

    checkTransaction();
  }, [timerTrigger]);

  const getSignerProvider = () => {
    if (!wallet?.provider) {
      setSignerProvider(null);
    } else {
      setSignerProvider(
        new ethers.providers.Web3Provider(
          wallet.provider,
          "any"
        ).getUncheckedSigner()
      );
    }
  };

  const freeVersionCheckHandler = () => {
    setIsFreeVersionChecked(!isFreeVersionChecked);
  };

  const ActuallyMakeTheMillionaire = async (
    isFreeVersionChecked,
    walletAddress
  ) => {
    try {
      let tx;
      let txReceipt;

      console.log(
        "ActuallyMakeTheMillionaire.isFreeVersion: ",
        isFreeVersionChecked
      );

      let millionaireCreatorTxAmount = 0;
      if (!isFreeVersionChecked) {
        millionaireCreatorTxAmount = config.feeInEth;
      }
      tx = {
        to: Helper.getMillionaireContractAddress(networkId),
        value: ethers.BigNumber.from(millionaireCreatorTxAmount * 10 ** 9).mul(
          1e9
        ),
      };

      txReceipt = await signerProvider.sendTransaction(tx);

      setMillionaireTxHashToCheck(txReceipt.hash);
      setMillionaireTxCheckerCount(0);
    } catch (error) {
      // millionaireContract?.removeAllListeners();
      setOnStartButtonActive(true);
      setBeAMillionaireMessage(new Message(error.message, MessageType.Error));
    }
  };

  const onStart = async (isFreeVersionChecked) => {
    if (!!activeWallet) {
      if (!Helper.isNetworkSupported(networkId)) {
        setBeAMillionaireMessage(
          new Message(
            Helper.createErrorMessageAboutSupportedNetworks(),
            MessageType.Error
          )
        );
        return;
      }

      let walletAddress = activeWallet?.accounts[0]?.address;
      let daiContract;
      try {
        setBeAMillionaireMessage(
          new Message("Starting the process ...", MessageType.Info)
        );

        setOnStartButtonActive(false);

        daiContract = new ethers.Contract(
          Helper.getDaiContractAddress(networkId),
          DaiABI,
          new ethers.providers.JsonRpcProvider(Helper.getRPCProvider(networkId))
        );
        const daiContractTxSender = new ethers.Contract(
          Helper.getDaiContractAddress(networkId),
          DaiABI,
          signerProvider
        );

        let allowance = await daiContract.allowance(
          activeWallet?.accounts[0]?.address,
          Helper.getMillionaireContractAddress(networkId)
        );
        console.log("Allowance: ", Number(allowance));

        let requiredAllowedAmount = 0;
        if (isFreeVersionChecked) {
          requiredAllowedAmount = config.minimumMillion;
        } else {
          requiredAllowedAmount = config.flashMillionAmount;
        }
        console.log("Required allowed amount: ", requiredAllowedAmount);

        if (allowance < BigInt(requiredAllowedAmount) * 10n ** 18n) {
          let approvalTx = await daiContractTxSender.approve(
            Helper.getMillionaireContractAddress(networkId),
            BigInt(requiredAllowedAmount) * 10n ** 18n
          );
          console.log("Approval transaction:", approvalTx.hash);
          setBeAMillionaireMessage(
            new Message(
              "Waiting for approval transaction to be completed...",
              MessageType.Info
            )
          );

          setDaiConfirmationTxHashToCheck(approvalTx.hash);
          setDaiConfirmationTxCheckerCount(0);
        } else {
          await ActuallyMakeTheMillionaire(isFreeVersionChecked, walletAddress);
        }
      } catch (error) {
        // daiContract.removeAllListeners();
        setOnStartButtonActive(true);
        setBeAMillionaireMessage(new Message(error.message, MessageType.Error));
      }
    } else {
      setBeAMillionaireMessage(
        new Message("Wallet is not connected."),
        MessageType.Error
      );
    }
  };

  return (
    <div className="d-flex container-fluid flex-grow-1" id="beamillionaire">
      <div className="d-flex flex-column flex-fill container body-content">
        <div className="jumbotron">
          <h1 className="display-4">
            Start the feeling of being a millionaire
          </h1>
          <p className="lead">
            Please make sure you are familiar with the{" "}
            <a href="#about">process</a> and get started.
          </p>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              name="freeversionCheckbox"
              id="freeversionCheckbox"
              checked={isFreeVersionChecked}
              onChange={freeVersionCheckHandler}
            />
            <label htmlFor="freeversionCheckbox">
              Free version (Equivalent of{" "}
              {Number(minimumMillionAmount).toLocaleString()} USD, instead of{" "}
              {Number(flashMillionAmount).toLocaleString()} USD for non-free
              version, only gas fee required).
            </label>
          </div>

          <hr className="my-4" />
          <p>
            {!!beAMillionaireMessage && !!beAMillionaireMessage.MessageText ? (
              <span
                className={Message.deriveMessageClass(
                  beAMillionaireMessage.MessageType
                )}
                dangerouslySetInnerHTML={{
                  __html: beAMillionaireMessage.MessageText,
                }}
              >
                {/* {beAMillionaireMessage.MessageText} */}
              </span>
            ) : !!activeWallet ? (
              <span className="text-dark">
                &nbsp;Eth balance is: {activeWallet?.accounts[0]?.balance?.ETH}.
                {console.log("Balance: ", activeWallet?.accounts[0]?.balance)}
              </span>
            ) : (
              <></>
            )}
          </p>
          <p className="lead">
            <a
              className={[
                !!activeWallet && onStartButtonActive
                  ? "btn btn-primary"
                  : "btn-secondary",
                "btn-lg",
                !!activeWallet && onStartButtonActive ? "" : "disabled",
              ].join(" ")}
              // false makes the attribute to disappear
              // https://stackoverflow.com/questions/31163693/how-do-i-conditionally-add-attributes-to-react-components
              href={!!activeWallet && onStartButtonActive ? "#" : false}
              role="button"
              onClick={
                !!activeWallet && onStartButtonActive
                  ? () => {
                      onStart(isFreeVersionChecked);
                    }
                  : () => {}
              }
            >
              Get started
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default BeAMillionaire;
