/*
const MessageType = {
  Error: "error",
  Info: "info",
  Success: "succcess",
};
*/

/*
class MessageType {
  static Error = new MessageType("error");
  static Info = new MessageType("info");
  static Success = new MessageType("success");

  constructor(name) {
    this.name = name;
  }

  static deriveMessageClass = (messageType) => {
    switch (messageType.name) {
      case MessageType.Info.name:
        return "text-dark";
      case MessageType.Success.name:
        return "text-success";
      case MessageType.Error.name:
        return "text-danger";
      default:
        return "text-warning";
    }
  };
}
  */

const MessageType = {
  Error: Symbol("error"),
  Info: Symbol("info"),
  Success: Symbol("success"),
};

export default MessageType;
