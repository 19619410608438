import React from "react";
import config from "../config/developmentConfig.json";
import Helper from "../Helper.js";

const Navbar = ({ onConnect, wallet, connectedChain }) => {
  return (
    <>
      <nav className="navbar navbar-expand navbar-light bg-light mb-4">
        <div className="container">
          <div className="collapse navbar-collapse">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item active">
                <a className="nav-link" href="#">
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#beamillionaire">
                  Be A Millionaire
                </a>
              </li>
              {/* <li className="nav-item">
                <a className="nav-link" href="#issuenft">
                  Issue NFT
                </a>
              </li> */}
              <li className="nav-item">
                <a className="nav-link" href="#about">
                  About
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#contact">
                  Contact
                </a>
              </li>
            </ul>
          </div>
          {!wallet && (
            <button
              className="btn btn-danger ml-auto"
              type="button"
              onClick={onConnect}
            >
              Connect
            </button>
          )}
          {wallet &&
            Helper.isNetworkSupported(parseInt(connectedChain?.id)) && (
              <div className="ml-auto">
                Wallet connected. Chain is:{" "}
                {Helper.getNetworkName(parseInt(connectedChain.id))}
              </div>
            )}
          {wallet &&
            !Helper.isNetworkSupported(parseInt(connectedChain?.id)) && (
              <div className="ml-auto">Chain is not supported.</div>
            )}
        </div>
      </nav>
    </>
  );
};

export default Navbar;
