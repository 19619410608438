import React from "react";

const Contact = () => {
  return (
    <div className="d-flex container-fluid flex-grow-1" id="contact">
      <div className="d-flex flex-column flex-fill container body-content">
        <div className="jumbotron">
          <h1 className="display-4">Contact</h1>
          <p>
            <a href="mailto:support@flashmillionaire.com">
              support@flashmillionaire.com
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Contact;
