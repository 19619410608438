import MessageType from "./MessageType";

class Message {
  constructor(messageText, messageType) {
    // if (typeof messageType != typeof MessageType) {
    /*
    if (!(messageType instanceof MessageType)) {
      throw new Error("messageType should be of type MessageType");
    }
    */
    // if (typeof messageText != typeof string) {
    if (messageText !== null && typeof messageText !== "string") {
      throw new Error("message should be of type string");
    }
    this.messageType_ = messageType;
    this.messageText_ = messageText;
  }

  get MessageText() {
    return this.messageText_;
  }

  get MessageType() {
    return this.messageType_;
  }

  static deriveMessageClass(messageType) {
    switch (messageType) {
      case MessageType.Info:
        return "text-dark";
      case MessageType.Success:
        return "text-success";
      case MessageType.Error:
        return "text-danger";
      /*
      default:
        return "text-warning";
        */
    }
  }
}

export default Message;
