/* global BigInt */

import "./App.css";
import { ethers } from "ethers";
import React, { useEffect, useCallback } from "react";
import { useState } from "react";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import BeAMillionaire from "./components/BeAMillionaire";
import { DaiABI, DummyWorkerABI, MillionaireABI } from "./components/ABIs.js";
import IssueNFT from "./components/IssueNFT";
import Contact from "./components/Contact";
import About from "./components/About";
import Constants from "./components/Constants";
import Message from "./components/Message";
import MessageType from "./components/MessageType";
import { useMutex } from "react-context-mutex";
import config from "./config/developmentConfig.json";
import Helper from "./Helper.js";
import {
  useAccountCenter,
  useConnectWallet,
  useNotifications,
  useSetChain,
  useWallets,
  useSetLocale,
  useWagmiConfig,
} from "@web3-onboard/react";
import {
  initWeb3Onboard,
  ethMainnetGasBlockPrices,
  infuraRPC,
} from "./web3onboardServices";

const DevelopmentFlag = true;

console.log(
  "The first supported network is: ",
  Constants[config.supportedNetwords[2]]
);

console.log(
  "The length supported network array is: ",
  config.supportedNetwords.length
);

function App() {
  const [connected, _setConnected] = useState(false);
  const [walletAddress, setWalletAddress] = useState(0x0);
  const [walletNetwork, setWalletNetwork] = useState(0x0);
  const [web3ModalProvider, _setWeb3ModalProvider] = useState(null);
  const [daiContractAddress, setDaiContractAddress] = useState(0x0);
  const [daiBalance, setDaiBalance] = useState(0);
  const [beAMillionaireMessage, setBeAMillionaireMessage] = useState(
    new Message(null, MessageType.Info)
  );

  // An integer which when the update to Nft section is required, is increased.
  // ToDo: This kind of state change to fire useEffect may good to be improved
  // This flag is updated in timer function and when nft efresh button is clicked
  const [globalUpdateNftFlag, setGlobalUpdateNftFlag] = useState(0);
  const [web3Onboard, setWeb3Onboard] = useState(null);
  const [{ wallet }, connect, disconnect, updateBalances, setWalletModules] =
    useConnectWallet();
  const [{ chains, connectedChain, settingChain }, setChain] = useSetChain();

  const MutexRunner = useMutex();
  const mutex = new MutexRunner("walletConnect");

  useEffect(() => {
    const fetchData = async () => {
      if (connected) {
        if (Helper.isNetworkSupported(walletNetwork)) {
          const daiContract = new ethers.Contract(
            daiContractAddress,
            DaiABI,
            web3ModalProvider
          );

          let newDaiBalance = await daiContract.balanceOf(walletAddress);
          if (!newDaiBalance.eq(daiBalance)) {
            setDaiBalance(newDaiBalance);
          }
        }
      }
    };

    fetchData();
  });

  useEffect(() => {
    setWeb3Onboard(initWeb3Onboard);
  }, []);

  let lockOwner = null;

  // ToDo: If a function for example onAccountStateChanged is called twice, the lock mechanism does not work and the
  // second call will enter the first call, and the second release will have null lockOwner; it is probably not a problem
  // because it is the same function but this behaviour will be good to be improved.
  async function acquireLock(ownerName) {
    let returnValue = false;

    mutex.run(async () => {
      mutex.lock();
      console.log("Mutex lock entered.");
      if (lockOwner === null) {
        console.log("lockOwner set.");
        lockOwner = ownerName;
      }
      mutex.unlock();

      if (lockOwner === ownerName) {
        console.log(
          "acquireLock returns true. Requester: ",
          ownerName,
          " Owner: ",
          lockOwner
        );
        returnValue = true;
      } else {
        console.log(
          "acquireLock returns false. Requester: ",
          ownerName,
          " Owner: ",
          lockOwner
        );
        returnValue = false;
      }
    });

    return returnValue;
  }

  async function releaseLock() {
    console.log("Lock released.", lockOwner);
    lockOwner = null;
  }

  const onConnect = async () => {
    let result = await acquireLock("onConnect");
    console.log("Lock aquire result: ", result);
    while (!(await acquireLock("onConnect")));
    console.log("Function onConnect entered.");
    if (connected === true) {
      console.log("onConnect is called, but already connected");
    } else {
      await connectWallet(); // For some reason it seems to work without await, but without await synchronization gets messed up
    }
    setGlobalUpdateNftFlag(globalUpdateNftFlag + 1);
    console.log("Function onConnect exited.");
    releaseLock();
  };

  async function connectWallet() {
    console.log("connectWallet entered.");
    const walletSelected = await connect();
    console.log("connected wallets: ", walletSelected);
    console.log("The wallet variable: ", wallet);
    console.log("connectWallet exited.");
  }

  return (
    <>
      <Navbar
        onConnect={onConnect}
        wallet={wallet}
        connectedChain={connectedChain}
      />
      <BeAMillionaire
        wallet={wallet}
        walletChain={connectedChain}
        flashMillionAmount={config.flashMillionAmount}
        minimumMillionAmount={config.minimumMillion}
      />
      <About
        feeInDai={config.feeInDai}
        flashMillionAmount={config.flashMillionAmount}
        daiContractAddressSepolia={config.daiContractAddressSepolia}
        daiLenderSepolia={config.daiLenderSepolia}
        daiFaucetSepolia={config.daiFaucetSepolia}
        minimumMillionAmount={config.minimumMillion}
        millionaireContractAddressMainnet={
          config.millionaireContractAddressMainnet
        }
        millionaireContractAddressSepolia={
          config.millionaireContractAddressSepolia
        }
        registryContractAddressMainnet={config.registryContractAddressMainnet}
        registryContractAddressSepolia={config.registryContractAddressSepolia}
      />
      <Contact />
      <Footer />
    </>
  );
}

export default App;
