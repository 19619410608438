import React from "react";
import config from "../config/developmentConfig.json";

const About = ({
  minimumMillionAmount,
  flashMillionAmount,
  daiContractAddressSepolia,
  daiLenderSepolia,
  daiFaucetSepolia,
  millionaireContractAddressMainnet,
  millionaireContractAddressSepolia,
  registryContractAddressMainnet,
  registryContractAddressSepolia,
}) => {
  return (
    <div className="d-flex container-fluid flex-grow-1" id="about">
      <div className="d-flex flex-column flex-fill container body-content">
        <div className="jumbotron">
          <h1 className="display-4">About</h1>
          <p>
            How would it feel, if {Number(flashMillionAmount).toLocaleString()}{" "}
            USD was transacted through your account?
          </p>
          <p>
            There is an idea saying that if somebody wants to achieve something,
            (s)he should see that thing clearly in her/his mind and believe
            (s)he can achieve it.
          </p>
          <p>
            This website tries to help its users to believe they can achieve
            large sums of money. Currently, the site does this by passing large
            number of million dollars through the user account; this was not
            possible before the advent of blockchain.
          </p>
          <p>
            Blockchain has enabled some type of loan called flash loan, which
            does not need any collateral, but it should be returned within the
            same transaction. This website uses the flash loan on Ethereum
            blockchain to get a flash loan of up to{" "}
            {Number(flashMillionAmount).toLocaleString()} dollar (in Dai token),
            put it in the provided account and then take it back. The fee for
            the non-free version ({Number(flashMillionAmount).toLocaleString()}{" "}
            USD) is currently {config.feeInEth} Eth which should be available in
            the account before starting the process. Free version does not
            require fee. Both will require netwrok fee for sending the
            transaction.
          </p>
          <p>
            The steps are:
            <ul>
              <li>
                The first step is to have or creare an account with enough
                balance to cover network fees and the Flashmilionaire.com fee
                (if the non-free version is used).
              </li>
              <li>
                The second step of the process is to approve a transaction so
                the designed smart contracts can take the amount of loan back
                from your account (Currently{" "}
                {Number(flashMillionAmount).toLocaleString()} Dai for the
                non-free version and{" "}
                {Number(minimumMillionAmount).toLocaleString()} Dai for the free
                version).
              </li>
              <li>
                The third step is to confirm the actual transaction which
                transfer millions to your account and gets it back.
              </li>
            </ul>
          </p>
          <p>
            In order to confirm your account had million(s), you can search your
            respective transaction hash on an Ethereum block explorer like{" "}
            <a href="https://etherscan.io" target="_blank">
              etherscan.io
            </a>
            , and you will see something like following:
            <img
              src="images/sampleMillionaireTransaction.png"
              class="img-fluid"
            />
          </p>
          <p>
            It may be more comfortable, to follow the steps using an account
            containing only the required eth for sending the transactions. The
            smart contracts are available for both Ethereum mainnet and Sepoila
            testnet to become familiar with the process. Contracts are available
            as the following (NFT contracts are designed for possible NFT
            issuance for users of the paid version in future):
          </p>
          <div>
            <b>Ethereum Mainnet:</b>
            <ul>
              <li>
                The main smart contract address:{" "}
                <a
                  href={
                    "https://etherscan.io/address/" +
                    millionaireContractAddressMainnet
                  }
                  target="_blank"
                >
                  {millionaireContractAddressMainnet}
                </a>
              </li>
              <li>
                The NFT (registry) smart contract address:{" "}
                <a
                  href={
                    "https://etherscan.io/address/" +
                    registryContractAddressMainnet
                  }
                  target="_blank"
                >
                  {registryContractAddressMainnet}
                </a>
              </li>
            </ul>
          </div>

          <div>
            <b>Ethereum Sepolia testnet:</b>
            <ul>
              <li>
                The main smart contract address:{" "}
                <a
                  href={
                    "https://sepolia.etherscan.io/address/" +
                    millionaireContractAddressSepolia
                  }
                  target="_blank"
                >
                  {millionaireContractAddressSepolia}
                </a>
              </li>
              <li>
                The NFT (registry) smart contract address:{" "}
                <a
                  href={
                    "https://sepolia.etherscan.io/address/" +
                    registryContractAddressSepolia
                  }
                  target="_blank"
                >
                  {registryContractAddressSepolia}
                </a>
              </li>
              <li>
                FUSD smart contract address:{" "}
                <a
                  href={
                    "https://sepolia.etherscan.io/address/" +
                    daiContractAddressSepolia
                  }
                  target="_blank"
                >
                  {daiContractAddressSepolia}
                </a>
              </li>
              <li>
                FUSD Lender:{" "}
                <a
                  href={
                    "https://sepolia.etherscan.io/address/" + daiLenderSepolia
                  }
                  target="_blank"
                >
                  {daiLenderSepolia}
                </a>
              </li>
              <li>
                FUSD Faucet:{" "}
                <a
                  href={
                    "https://sepolia.etherscan.io/address/" + daiFaucetSepolia
                  }
                  target="_blank"
                >
                  {daiFaucetSepolia}
                </a>
              </li>
            </ul>
            <p>
              Since Dai is not offcially available on Sepolia testnet, a custom
              token (FUSD) and its lender is created on Sepolia blockchain. To
              get Sepolia Eth, you can use faucets like:{" "}
              <a href="https://sepoliafaucet.com/" target="_blank">
                https://sepoliafaucet.com/
              </a>
              . To get FUSD, you can use{" "}
              <a
                href={
                  "https://sepolia.etherscan.io/address/" + daiFaucetSepolia
                }
                target="_blank"
              >
                FUSD faucet
              </a>
              . For each 0.01 Sepolia Eth you send to the contract address ({" "}
              {daiFaucetSepolia} ), you will receive back 50 FUSD.
            </p>
            <p>
              Please also note that:
              <ul>
                <li>
                  Anywhere USD is mentioned, it is about Dai equivalent in
                  mainnet and FUSD in sepolia testnet.{" "}
                  <a href="https://en.wikipedia.org/wiki/Dai_(cryptocurrency)">
                    Dai
                  </a>{" "}
                  is soft-pegged to USD.
                </li>
                <li>
                  If there are any issues in using the website, smart contracts
                  can be invoked directly using a platform like{" "}
                  <a href="https://etherscan.io/">etherscan.io</a> .
                </li>
                <li>
                  If you are using a mobile device, the browser of the wallet
                  app is recommended.
                </li>
              </ul>
            </p>
            <p>
              <h2 className="display-5">About NFTs</h2>
              There are two types of NFTs:
              <ul>
                <li>
                  Soulbound NFTs: There is an idea to issue soulbound NFTs for
                  paid clients so that they can keep track of their feeling more
                  easily; it is not finalized yet.
                </li>
                <li>
                  <a
                    href="https://x2y2.io/collection/flashmillionairenft/items"
                    target="_blank"
                  >
                    Preminted NFTs
                  </a>
                  : In order to raise about 10,000 USD at the luanch of
                  flashmillionaire.com a collection of NFTs were issued to be
                  buyed backed at 5 fold of intial sale price when the profit of
                  flashmillionaire.com reached 1,000,000 USD. The terms for
                  these NFTs are (For previous terms please click{" "}
                  <a href="./prevterms.html" target="_blank">
                    here
                  </a>
                  ):
                  <ol>
                    <li>
                      Although the sales are done in ETH, the investment amount
                      is considered to be the USD equivalent of the payment as
                      detailed in the next item.
                    </li>
                    <li>
                      Regrdgless of the amount paid in ETH, the purchase price
                      for NFTs numbered form 0 to 999 is considered to be 10
                      dollars, 1000 to 1499 is considered to be 20 dollars and
                      1500 to 1699 is considered to be 50 dollars, 1700 to 2699
                      is considered to be 10 dollars, 2700 to 3199 is considered
                      to be 20 dollars and 3200 to 3399 is considered to be 50
                      dollars. The ETH price for NFTs is tried to be set in such
                      a way that the stated prices are logical at the time of
                      sale.
                    </li>
                    <li>
                      If more than 10,000$ of NFTs are sold, all of them will be
                      considered for 5 fold repurchase (Actually 30,000$ worth
                      of NFTs are listed in Exchange in various prices to
                      satisfy different tastes). NTFs numbered above 1700 are
                      not normally for sale, and are reserved if special
                      conditions occur.
                    </li>
                    <li>
                      If an NFT price is considerably higher than the mentioned
                      amounts (for example 100000000 ETH) it means that, the NFT
                      is not for sale anymore, this price change is to indicate
                      the sale stop. Under all conditions the price assumptions
                      detailed in the before last item will be effective.
                    </li>
                    <li>
                      The buyback instrument will be Dai or its equivalent.
                    </li>
                    <li>
                      If the buyer does not claim its 5 foldeded investment back
                      in a timely manner, flashmillionaire.com can pay the buyer
                      address address on its own.
                    </li>
                    <li>
                      The buyer accepts what flashmillionaire.com declares as
                      its profit.
                    </li>
                    <li>
                      If it was decided to stop flashmillionaire.com before
                      1,000,000 USD in profit, it means there will be no
                      buyback.
                    </li>
                    <li>
                      Buyer is responsible for complying with their local laws.
                      Due to legal restrictions, purchasing NFTs or using this
                      website is prohibited for U.S. Persons.
                    </li>
                    <li>
                      If the terms change, the buyer accepts the new terms
                      automatically or will her/sell his NFTs immediately, the
                      terms will not normally change against buyer in terms of
                      buyback price or the required flashmillionaire.com profit.
                    </li>
                    <li>
                      Other possible uncovered issues will be solved by common
                      sense.
                    </li>
                  </ol>
                </li>
              </ul>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
