import config from "./config/developmentConfig.json";
import Constants from "./components/Constants";

class Helper {
  constructor() {}

  // https://etherscan.io/tx/0x0bf003e55cc64d1a59d10da3d99face4c7eef9aa4235b773aa3c6989c12714d5
  // https://goerli.etherscan.io/tx/0x423277fa8eb822bb2fbadd32ab9ea911ce8b28334d330955b6cb1f3d9aa09cdb
  static getEtherScanUri(network, txId) {
    let networkName = null;
    switch (network) {
      case Constants.EthereumMainNetNetworkId:
        networkName = "";
        break;
      case Constants.EthereumGoerliNetworkId:
        networkName = "goerli.";
        break;
      case Constants.EthereumSepoliaNetworkId:
        networkName = "sepolia.";
        break;
      default:
        return null;
    }

    let uri = "https://";
    return uri.concat(networkName, "etherscan.io/tx/", txId);
  }

  // https://opensea.io/assets/ethereum/0xd2a077ec359d94e0a0b7e84435eacb40a67a817c/1
  // https://testnets.opensea.io/assets/sepolia/0x9036250a8ec7070f82b969c7c9fb4353ef4d8338/1
  // https://testnets.opensea.io/assets/goerli/0xe29f8038d1a3445ab22ad1373c65ec0a6e1161a4/14
  static getOpenseaUri(network, nftAddress, nftId) {
    let networkName = null;
    let prefix = null;
    switch (network) {
      case Constants.EthereumMainNetNetworkId:
        prefix = "";
        networkName = "ethereum";
        break;
      case Constants.EthereumGoerliNetworkId:
        prefix = "testnets.";
        networkName = "goerli";
        break;
      case Constants.EthereumSepoliaNetworkId:
        prefix = "testnets.";
        networkName = "sepolia";
        break;
      default:
        return null;
    }

    let uri = "https://";
    return uri.concat(
      prefix,
      "opensea.io/assets/",
      networkName,
      "/",
      nftAddress,
      "/",
      nftId
    );
  }

  static getNetworkIndex(network) {
    for (let i = 0; i < config.supportedNetwords.length; i++) {
      if (Constants[config.supportedNetwords[i]] === network) {
        return i;
      }
    }

    return -1;
  }

  static isNetworkSupported(network) {
    if (this.getNetworkIndex(network) === -1) {
      return false;
    } else {
      return true;
    }
  }

  static getNetworkName(networkId) {
    switch (networkId) {
      case Constants["EthereumMainNetNetworkId"]:
        return Constants["EthereumMainNetNetworkName"];
      case Constants["EthereumGoerliNetworkId"]:
        return Constants["EthereumGoerliNetworkName"];
      case Constants["EthereumSepoliaNetworkId"]:
        return Constants["EthereumSepoliaNetworkName"];
      default:
        return "Invalid Network id";
    }
  }

  static createErrorMessageAboutSupportedNetworks() {
    let prefix = null;
    if (config.supportedNetworkNames.length < 2) {
      prefix = "Network should be ";
    } else {
      prefix = "Network should be either ";
    }
    return prefix + config.supportedNetworkNames.join(" or ") + ".";
  }

  static getMillionaireContractAddress(network) {
    switch (network) {
      case Constants.EthereumMainNetNetworkId:
        return config.millionaireContractAddressMainnet;
      case Constants.EthereumSepoliaNetworkId:
        return config.millionaireContractAddressSepolia;
      default:
        return null;
    }
  }

  static getDaiContractAddress(network) {
    switch (network) {
      case Constants.EthereumMainNetNetworkId:
        return config.daiContractAddressMainnet;
      case Constants.EthereumSepoliaNetworkId:
        return config.daiContractAddressSepolia;
      default:
        return null;
    }
  }

  static getRegistryContractAddress(network) {
    switch (network) {
      case Constants.EthereumMainNetNetworkId:
        return config.registryContractAddressMainnet;
      case Constants.EthereumSepoliaNetworkId:
        return config.registryContractAddressSepolia;
      default:
        return null;
    }
  }

  static getRPCProvider(network) {
    switch (network) {
      case Constants.EthereumMainNetNetworkId:
        return config.mainnetRPC;
      case Constants.EthereumSepoliaNetworkId:
        return config.sepoliaRPC;
      default:
        return null;
    }
  }
}

export default Helper;
